<script>
import {
    ArrowUpIcon,
    ArrowRightIcon
} from 'vue-feather-icons';

import Navbar from "@/components/navbar0";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";

/**
 * Page-aboutus-two component
 */
export default {
    data() {
        return {}
    },
    components: {
        Navbar,
        Switcher,
        Footer,
        ArrowUpIcon,
        ArrowRightIcon
    }
}
</script>

<template>
<div>
    <Navbar :nav-light="true" />

    <!-- Hero Start
    <section class="bg-half d-table w-100" style="background: url('images/company/aboutus.jpg');">
      -->
  <section class="bg-half d-table w-100">
        <div class="bg-overlay"></div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-12 text-center">
                    <div class="page-next-level title-heading">
                        <h1 class="text-white title-dark"> О нас </h1>
                        <p class="text-white-50 para-desc mb-0 mx-auto">Начните работу с Skillrank.</p>
                        <div class="page-next">
                            <nav aria-label="breadcrumb" class="d-inline-block">
                                <ul class="breadcrumb bg-white rounded shadow mb-0">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">Skillrank</router-link>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">О нас</li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
        <div class="shape overflow-hidden text-white">
            <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
            </svg>
        </div>
    </div>
    <!-- Hero End -->

    <section class="section">
        <div class="container">
            <div class="row align-items-center" id="counter">
                <div class="col-md-6">
                    <img src="images/company/about_anton.jpg" class="img-fluid" alt="">
                </div>
                <!--end col-->

                <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div class="ml-lg-4">
                        <div class="d-flex mb-4">
                            <span class="text-primary h1 mb-0"><span class="counter-value display-1 font-weight-bold" data-count="15">2</span>+</span>
                            <span class="h6 align-self-end ml-2">года <br> на рынке</span>
                        </div>
                        <div class="section-title">
                            <h4 class="title mb-4">Кто мы ?</h4>
                            <p class="text-muted">Мы <span class="text-primary font-weight-bold">Skillrank</span>
                              - мы оздаем инструменты, позволяющие быстрее находить людей и работу.</p>
                            <a href="javascript:void(0)" class="btn btn-primary mt-3">Свяжитесь с нами</a>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->

        <div class="container mt-4">
            <div class="row justify-content-center">
                <div class="col-lg-2 col-md-4 col-6 text-center pt-4">
                    <img src="images/client/sber1.jpg" class="avatar avatar-ex-sm" alt="">
                </div>
                <!--end col-->

                <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                    <img src="images/client/moex.png" class="avatar avatar-ex-sm" alt="">
                </div>
                <!--end col-->

                <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                    <img src="images/client/yandexmarket.png" class="avatar avatar-ex-sm" alt="">
                </div>

              <!--

                <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                    <img src="images/client/paypal.svg" class="avatar avatar-ex-sm" alt="">
                </div>


                <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                    <img src="images/client/shopify.svg" class="avatar avatar-ex-sm" alt="">
                </div>


                <div class="col-lg-2 col-md-2 col-6 text-center pt-4">
                    <img src="images/client/spotify.svg" class="avatar avatar-ex-sm" alt="">
                </div>
                end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
    </section>
    <!--end section-->

    <section class="section bg-light">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12">
                    <div class="section-title text-center mb-4 pb-2">
                        <h6 class="text-primary">Процесс поиска сотрудника</h6>
                        <h4 class="title mb-4">Как мы работаем?</h4>
                        <p class="text-muted para-desc mx-auto mb-0">Благодаря <span class="text-primary font-weight-bold">Skillrank</span>
                          не нужно тратить время и деньги на сорсинг воронки кандидатов.</p>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->

            <div class="row">
                <div class="col-md-4 mt-4 pt-2">
                    <div class="card features work-process bg-transparent process-arrow border-0 text-center">
                        <div class="icons rounded h1 text-center text-primary px-3">
                            <i class="uil uil-presentation-edit"></i>
                        </div>

                        <div class="card-body">
                            <h4 class="title text-dark">Поиск</h4>
                            <p class="text-muted mb-0">Вы ищите резюме с помощью интеллектуального поиска, первыми в выдаче появляются резюме, которые наиболее подходят
                              под ключевые слова.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-md-5 pt-md-3 mt-4 pt-2">
                    <div class="card features work-process bg-transparent process-arrow border-0 text-center">
                        <div class="icons rounded h1 text-center text-primary px-3">
                            <i class="uil uil-airplay"></i>
                        </div>

                        <div class="card-body">
                            <h4 class="title text-dark">Рассылка</h4>
                            <p class="text-muted mb-0">Вам не треубется ждать когда кандидату найдут и прочитают вакансию если кандидат подходит сразу напишите ему.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-4 mt-md-5 pt-md-5 mt-4 pt-2">
                    <div class="card features work-process bg-transparent d-none-arrow border-0 text-center">
                        <div class="icons rounded h1 text-center text-primary px-3">
                            <i class="uil uil-image-check"></i>
                        </div>

                        <div class="card-body">
                            <h4 class="title text-dark">Аналитика</h4>
                            <p class="text-muted mb-0">Управляйте конверсиями воронки с помошью удобных аналитических инструментами, которые встроены в платформу.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->
            </div>
            <!--end row-->
        </div>
        <!--end container-->
        <!--
        <div class="container mt-100 mt-60">
            <div class="row align-items-end mb-4 pb-4">
                <div class="col-md-8">
                    <div class="section-title text-center text-md-left">
                        <h6 class="text-primary">Services</h6>
                        <h4 class="title mb-4">What we do ?</h4>
                        <p class="text-muted mb-0 para-desc">Start working with <span class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    </div>
                </div>


                <div class="col-md-4 mt-4 mt-sm-0">
                    <div class="text-center text-md-right">
                        <a href="javascript:void(0)" class="text-primary h6">See More <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                    </div>
                </div>

            </div>


            <div class="row">

                <b-tabs vertical justified pills nav-class="bg-white p-3 mb-0 flex-column" nav-wrapper-class="col-md-4 mt-4" content-class="col-md-8 col-12  mt-4 pt-2">
                    <b-tab>
                        <template v-slot:title>
                            <div class="text-center pt-1 pb-1">
                                <h6 class="title font-weight-normal mb-0">Web Developing</h6>
                            </div>
                        </template>
                        <img src="images/work/7.jpg" class="img-fluid rounded shadow" alt="">
                        <div class="mt-4">
                            <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.</p>
                            <a href="javascript:void(0)" class="text-primary">See More <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                        </div>
                    </b-tab>
                    <b-tab title-item-class="mt-2">
                        <template v-slot:title>
                            <div class="text-center pt-1 pb-1">
                                <h6 class="title font-weight-normal mb-0">Database Analysis</h6>
                            </div>
                        </template>
                        <img src="images/work/8.jpg" class="img-fluid rounded shadow" alt="">
                        <div class="mt-4">
                            <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.</p>
                            <a href="javascript:void(0)" class="text-primary">See More <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                        </div>
                    </b-tab>
                    <b-tab title-item-class="mt-2">
                        <template v-slot:title>
                            <div class="text-center pt-1 pb-1">
                                <h6 class="title font-weight-normal mb-0">Server Security</h6>
                            </div>
                        </template>
                        <img src="images/work/9.jpg" class="img-fluid rounded shadow" alt="">
                        <div class="mt-4">
                            <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.</p>
                            <a href="javascript:void(0)" class="text-primary">See More <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                        </div>
                    </b-tab>
                    <b-tab title-item-class="mt-2">
                        <template v-slot:title>
                            <div class="text-center pt-1 pb-1">
                                <h6 class="title font-weight-normal mb-0">Web Designing</h6>
                            </div>
                        </template>
                        <img src="images/work/12.jpg" class="img-fluid rounded shadow" alt="">
                        <div class="mt-4">
                            <p class="text-muted">This is required when, for example, the final text is not yet available. Dummy text is also known as 'fill text'. It is said that song composers of the past used dummy texts as lyrics.</p>
                            <a href="javascript:void(0)" class="text-primary">See More <arrow-right-icon class="fea icon-sm"></arrow-right-icon></a>
                        </div>
                    </b-tab>
                </b-tabs>

            </div>

        </div>
        -->
    </section>

    <Footer />
    <!-- Footer End
    <Switcher />
    -->
    <!-- Back to top -->
    <a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" v-scroll-to="'#topnav'">
        <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
</div>
</template>

<style>
.modal-content {
    background-color: transparent;
}
</style>
